module.exports = {
	APP_NAME: 'Agriclear Admin',
	APP_VERSION: '1.0',
	WALLET_KEY: '0xa981e2c14fe041b9c0ec1c198fd0f05daf0922b9aa49a2a1c5eaf3eec7099dca',
	CONSUMER_VIEW_URL: process.env.REACT_APP_CONSUMER_VIEW_URL,
	BASE_URL: process.env.REACT_APP_API_SERVER,
	IPFS_URL: process.env.REACT_APP_IPFS_SERVER,
	ASSEST_URL: `${process.env.REACT_APP_ASSEST_URL}/rumsan-group`,
	POLYGON_EXPLORER: process.env.REACT_APP_POLYGON_EXPLORER,
	FETCH_LIMIT: 10,
	LIMIT_CROPMS_PRODUCTLIST: 100,
	APP_CONSTANTS: {
		VERSION: 1,
		PASSCODE_LENGTH: 6,
		LANGUAGE: {
			NP: 'np',
			EN: 'en',
		},
	},
	MAP: {
		DEFAULT_LAT: '27.70076',
		DEFAULT_LNG: '85.30014',
		ZOOM: 10,
	},
	ARCHIVE_REASONS: [
		{ label: 'Sold', value: 'Sold' },
		{ label: 'Removed', value: 'Removed' },
		{ label: 'Damaged', value: 'Damaged' },
		{ label: 'Others', value: 'Others' },
	],
	ACTIVITY_TYPE: [
		{ label: 'GENERIC', value: 'generic' },
		{ label: 'CUSTOM', value: 'custom' },
		{ label: 'RECURRING', value: 'recurring' },
	],
	TAGS: {
		// FERTILIZER: 'Fertilizer',
		// PESTICIDE: 'Pesticide',
		JOURNEY: 'Journey',
		CERTIFICATE: 'Certificate',
		AGRO_INPUTS: 'Agro Inputs',
		PRODUCT_DETAILS: 'Product Details',

		OTHER: 'Other',
	},
	QR_SIZE: {
		LARGE: 'Large',
		SMALL: 'Small',
	},
	AREA_METRICS: {
		HECTARE: 'Hectare',
		KATTHA: 'Kattha',
		ROPANI: 'Ropani',
		SQ_KM: 'Sq.Km',
	},

	METRICS: [
		{ label: 'None', value: 'None' },
		{
			label: 'Kattha',
			value: 'Kattha',
		},
		{ label: 'Ropani', value: 'Ropani' },
		{ label: 'Sq.Km', value: 'Sq.Km' },
	],

	ENTERPRISE_TYPES: {
		Open_Field: 'Open Field',
		Tunnel: 'Tunnel',
		Green_House: ' Green House',
	},
	ROLES: {
		ADMIN: 'Admin',
		BATCH_ADMIN: 'Batch Admin',
	},
	MEMBERS: {
		FARMER: 'Farmer',
		DISTRIBUTOR: 'Distributor',
	},

	SPLIT_NUM: ['2', '3', '4', '5', '6', '7', '8', '9', '10'],
};
