import React from 'react';
import { PATH_APP } from 'src/routes/paths';
import { MIcon } from 'src/theme';
import User from '@iconify-icons/ant-design/user-outline';
import { Icon } from '@iconify/react';
import {
	PeopleAltOutlined,
	ChaletOutlined,
	AgricultureOutlined,
	AccountTreeOutlined,
	CorporateFareOutlined,
	YardOutlined,
} from '@material-ui/icons';
// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
	dashboard: <MIcon src={path('ic_dashboard')} />,
	page: <MIcon src={path('ic_page')} />,
	users: <Icon icon={User} />,
	people: <PeopleAltOutlined />,
	farms: <ChaletOutlined />,
	farmers: <AgricultureOutlined />,
	distributors: <AccountTreeOutlined />,
	product: <YardOutlined />,
	batch: <AccountTreeOutlined />,
	enterprises: <CorporateFareOutlined />,
};

const navConfig = [
	{
		items: [
			{
				title: 'Dashboard',
				href: PATH_APP.report.list,
				icon: ICONS.dashboard,
			},
		],
	},
	{
		items: [
			{
				title: 'Enterprises',
				href: PATH_APP.enterprise.list,
				icon: ICONS.enterprises,
			},
		],
	},
	{
		items: [
			{
				title: 'Products',
				href: PATH_APP.product.list,
				icon: ICONS.product,
			},
		],
	},
	{
		items: [
			{
				title: 'Activities',
				href: PATH_APP.activity.list,
				icon: ICONS.farmers,
			},
		],
	},
	{
		items: [
			{
				title: 'Batches',
				href: PATH_APP.batch.list,
				icon: ICONS.batch,
			},
		],
	},

	{
		subheader: 'admin',
		items: [
			{
				title: 'Users',
				href: PATH_APP.admin.users.list,
				icon: ICONS.people,
			},
			{
				title: 'Farmers',
				href: PATH_APP.farmer.list,
				icon: ICONS.farmers,
			},
			{
				title: 'Distributors',
				href: PATH_APP.distributor.list,
				icon: ICONS.distributors,
			},
		],
	},
];

export default navConfig;
