import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from 'src/layouts/dashboardLayout';
import AuthProtect from 'src/global/Auth/AuthProtect';
import ComponentWrapper from 'src/global/ComponentWrapper';
const UserList = lazy(() => import('src/modules/user/list'));
const UserDetails = lazy(() => import('src/modules/user/details'));
// const FarmDetails = lazy(() => import('src/modules/farm/details'));
const EnterpriseDetail = lazy(() => import('src/modules/enterprise/details'));
const ProductDetails = lazy(() => import('src/modules/products/details'));
const BatchDetails = lazy(() => import('src/modules/batch/details'));
const FarmBatchDetails = lazy(() => import('src/modules/enterprise/batch/details'));
const BatchPreview = lazy(() => import('src/modules/batch/preview'));
const ReportList = lazy(() => import('src/modules/trackReport/list'));
// const FarmList = lazy(() => import('src/modules/farm/list'));
const EnterpriseList = lazy(() => import('src/modules/enterprise/list'));
const ProductList = lazy(() => import('src/modules/products/list'));
const BatchList = lazy(() => import('src/modules/batch/list'));
const ActivityList = lazy(() => import('src/modules/activities'));
const FloraActivities = lazy(() => import('src/modules/products/details/Activity/flora'));
const FaunaActivities = lazy(() => import('src/modules/products/details/Activity/fauna'));
const OthersActivities = lazy(() => import('src/modules/products/details/Activity/others'));
const DistributorList = lazy(() => import('src/modules/member/distributors'));
const FarmerList = lazy(() => import('src/modules/member/farmers'));
const DistributorDetails = lazy(() => import('src/modules/member/details'));
const Profile = lazy(() => import('src/modules/user/profile'));

// ----------------------------------------------------------------------

const AppRoutes = {
	path: PATH_APP.root,
	layout: DashboardLayout,
	guard: AuthProtect,
	routes: [
		{
			exact: true,
			path: PATH_APP.root,
			component: () => <Redirect to={PATH_APP.report.list} />,
		},
		{
			exact: true,
			path: PATH_APP.report.list,
			breadcrumbs: [
				{
					name: 'Track Report',
					href: PATH_APP.report.list,
				},
				{
					name: 'List',
				},
			],
			component: (props) => (
				<ComponentWrapper {...props}>
					<ReportList {...props} />
				</ComponentWrapper>
			),
		},
		// {
		// 	exact: true,
		// 	path: PATH_APP.farm.list,
		// 	breadcrumbs: [
		// 		{
		// 			name: 'Enterprises',
		// 			href: PATH_APP.farm.list,
		// 		},
		// 		{
		// 			name: 'List',
		// 		},
		// 	],
		// 	component: (props) => (
		// 		<ComponentWrapper {...props}>
		// 			<FarmList {...props} />
		// 		</ComponentWrapper>
		// 	),
		// },
		{
			exact: true,
			path: PATH_APP?.enterprise?.list,
			breadcrumbs: [
				{
					name: 'Enterprises',
					href: PATH_APP?.enterprise?.list,
				},
				{
					name: 'List',
				},
			],
			component: (props) => (
				<ComponentWrapper {...props}>
					<EnterpriseList {...props} />
				</ComponentWrapper>
			),
		},

		// {
		// 	exact: true,
		// 	path: PATH_APP.farm.details,
		// 	breadcrumbs: [
		// 		{
		// 			name: 'Enterprises',
		// 			href: PATH_APP.farm.list,
		// 		},
		// 		{
		// 			name: 'Details',
		// 		},
		// 	],
		// 	component: (props) => (
		// 		<ComponentWrapper {...props}>
		// 			<FarmDetails {...props} />
		// 		</ComponentWrapper>
		// 	),
		// },

		{
			exact: true,
			path: PATH_APP?.enterprise?.details,
			breadcrumbs: [
				{
					name: 'Enterprises',
					href: PATH_APP?.enterprise?.list,
				},
				{
					name: 'Details',
				},
			],
			component: (props) => (
				<ComponentWrapper {...props}>
					<EnterpriseDetail {...props} />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.product.list,
			breadcrumbs: [
				{
					name: 'Products',
					href: PATH_APP.product.list,
				},
				{
					name: 'List',
				},
			],
			component: (props) => (
				<ComponentWrapper {...props}>
					<ProductList {...props} />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.product.details,
			breadcrumbs: [
				{
					name: 'Products',
					href: PATH_APP.product.list,
				},
				{
					name: 'Details',
				},
			],
			component: (props) => (
				<ComponentWrapper {...props}>
					<ProductDetails {...props} />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.productActivity.floradetails,
			breadcrumbs: [
				{
					name: 'Products',
					href: PATH_APP.product.list,
				},
				{
					name: 'Activity',
					href: PATH_APP.product.list,
				},
				{
					name: 'List',
				},
			],
			component: (props) => (
				<ComponentWrapper {...props}>
					<FloraActivities {...props} />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.productActivity.faunadetails,
			breadcrumbs: [
				{
					name: 'Products',
					href: PATH_APP.product.list,
				},
				{
					name: 'Activity',
					href: PATH_APP.product.list,
				},
				{
					name: 'List',
				},
			],
			component: (props) => (
				<ComponentWrapper {...props}>
					<FaunaActivities {...props} />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.productActivity.othersdetails,
			breadcrumbs: [
				{
					name: 'Products',
					href: PATH_APP.product.list,
				},
				{
					name: 'Activity',
					href: PATH_APP.product.list,
				},
				{
					name: 'List',
				},
			],
			component: (props) => (
				<ComponentWrapper {...props}>
					<OthersActivities {...props} />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.activity.list,
			breadcrumbs: [
				{
					name: 'Activities',
					href: PATH_APP.activity.list,
				},
				{
					name: 'List',
				},
			],
			component: (props) => (
				<ComponentWrapper {...props}>
					<ActivityList {...props} />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.batch.list,
			breadcrumbs: [
				{
					name: 'Batches',
					href: PATH_APP.batch.list,
				},
				{
					name: 'List',
				},
			],
			component: (props) => (
				<ComponentWrapper {...props}>
					<BatchList {...props} />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.batch.details,
			breadcrumbs: [
				{
					name: 'Batches',
					href: PATH_APP.batch.list,
				},
				{
					name: 'Details',
				},
			],
			component: (props) => (
				<ComponentWrapper {...props}>
					<BatchDetails {...props} />
				</ComponentWrapper>
			),
		},

		{
			exact: true,
			path: PATH_APP.admin.users.list,
			breadcrumbs: [
				{
					name: 'Users',
					href: PATH_APP.admin.users.list,
				},
				{
					name: 'List',
				},
			],
			component: (props) => (
				<ComponentWrapper {...props}>
					<UserList />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.admin.users.details,
			breadcrumbs: [
				{
					name: 'Users',
					href: PATH_APP.admin.users.list,
				},
				{
					name: 'Details',
				},
			],
			component: (props) => (
				<ComponentWrapper {...props}>
					<UserDetails {...props} />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.batch.details,
			breadcrumbs: [
				{
					name: 'Farm',
					href: PATH_APP.farm.list,
				},
				{
					name: 'Batch Details',
				},
			],
			component: (props) => (
				<ComponentWrapper {...props}>
					<FarmBatchDetails {...props} />{' '}
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.batch.preview,
			component: (props) => <BatchPreview {...props} />,
		},

		{
			exact: true,
			path: PATH_APP.distributor.list,
			breadcrumbs: [
				{
					name: 'Distributors',
					href: PATH_APP.distributor.list,
				},
				{
					name: 'List',
				},
			],
			component: (props) => (
				<ComponentWrapper {...props}>
					<DistributorList {...props} />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.farmer.list,
			breadcrumbs: [
				{
					name: 'Farmers',
					href: PATH_APP.farmer.list,
				},
				{
					name: 'List',
				},
			],
			component: (props) => (
				<ComponentWrapper {...props}>
					<FarmerList {...props} />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.members.details,
			component: (props) => <DistributorDetails {...props} />,
		},
		{
			exact: true,
			path: '/profile',
			component: (props) => <Profile {...props} />,
		},

		// ----------------------------------------------------------------------
		{
			component: () => <Redirect to='/404' />,
		},
	],
};

export default AppRoutes;
