import React, { createContext, useCallback, useReducer } from 'react';
import userReduce from './reducers';
import * as Service from './services';
import * as ACTIONS from './actions';
import { FETCH_LIMIT } from '../../../constants';

const initialState = {
	user_info: {},
	list: [],
	pagination: { limit: FETCH_LIMIT, start: 0, total: 0, currentPage: 1, totalPages: 0 },
	map_options: { lat: '27.700769', lng: '85.300140', zoom: 12 },
};

export const UserContext = createContext(initialState);
export const UserContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(userReduce, initialState);

	function setMapOptions(options) {
		dispatch({ type: ACTIONS.SET_MAP_OPTIONS, data: options });
	}

	const listUser = useCallback(async (query) => {
		try {
			const res = await Service?.listUser(query);
			dispatch({ type: ACTIONS?.LIST_ALL_USERS, res });
			return res;
		} catch (err) {
			throw err?.data;
		}
	}, []);

	const deleteUser = useCallback(async (id) => {
		try {
			await Service.deleteUser(id);
			return;
		} catch (err) {
			throw err?.data;
		}
	}, []);

	const registerUser = async (payload, wallet, address, passcode) => {
		return new Promise((resolve, reject) => {
			Service.registerUser(payload, wallet, address, passcode)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const getUserDetails = useCallback(async (payload) => {
		try {
			const res = await Service?.getUserDetails(payload);
			return res?.data;
		} catch (err) {
			throw err?.data;
		}
	}, []);

	const getUserDetailsByWallet = async (payload) => {
		return new Promise((resolve, reject) => {
			Service.getUserDetailsByWallet(payload)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const getEnterpriseDetailsByName = async (payload) => {
		return new Promise((resolve, reject) => {
			Service.getEnterpriseDetailsByName(payload)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const updateUser = useCallback(async (params) => {
		try {
			const res = await Service?.updateUser(params);
			dispatch({ type: ACTIONS?.USER_DETAILS, res });
			return;
		} catch (err) {
			throw err?.data;
		}
	}, []);

	const updateUserStatus = useCallback(async (id, payload) => {
		try {
			await Service?.updateUserStatus(id, payload);
			return;
		} catch (err) {
			throw err?.data;
		}
	}, []);

	const updateApproval = useCallback(async (payload) => {
		try {
			await Service?.updateApproval(payload);
			return;
		} catch (err) {
			throw err?.data;
		}
	}, []);

	const updateImage = async (payload) => {
		try {
			await Service?.updateImage(payload);
			return;
		} catch (err) {
			throw err?.data;
		}
	};

	const updateImageEnterprise = async (payload) => {
		return new Promise((resolve, reject) => {
			Service.updateImageEnterprise(payload)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const uploadImage = async (base64) => {
		return new Promise((resolve, reject) => {
			Service.uploadImage({ file: base64 })
				.then((res) => {
					resolve(res?.data);
				})
				.catch((err) => {
					reject(err?.data);
				});
		});
	};

	function loginUsingMetamask(payload) {
		return new Promise((resolve, reject) => {
			Service.loginUsingMetamask(payload)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	const getRoles = async (payload) => {
		try {
			const res = await Service?.getRoles(payload);
			return res?.data;
		} catch (err) {
			throw err?.data;
		}
	};

	const updateRole = async (payload) => {
		try {
			const res = await Service?.updateRole(payload);
			dispatch({ type: ACTIONS?.UPDATE_ROLE, res });
			return;
		} catch (err) {
			throw err?.data;
		}
	};

	const updateProfile = async (payload) => {
		return new Promise((resolve, reject) => {
			Service.updateProfile(payload)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};
	const updateEnterpriseDetails = async (payload) => {
		return new Promise((resolve, reject) => {
			Service.updateEnterpriseDetails(payload)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const addUser = async (payload) => {
		try {
			await Service.addUser(payload);
			return;
		} catch (err) {
			throw err?.data;
		}
	};

	const getAppID = useCallback(async () => {
		try {
			const res = await Service?.getAppID();
			return res?.data;
		} catch (err) {
			throw err?.data;
		}
	}, []);

	return (
		<UserContext.Provider
			value={{
				list: state.list,
				pagination: state.pagination,
				map_options: state.map_options,
				user_info: state.user_info,
				addUser,
				listUser,
				dispatch,
				deleteUser,
				getUserDetails,
				updateUser,
				updateUserStatus,
				uploadImage,
				loginUsingMetamask,
				setMapOptions,
				updateApproval,
				registerUser,
				getRoles,
				updateRole,
				getUserDetailsByWallet,
				updateProfile,
				getAppID,
				updateImage,
				updateEnterpriseDetails,
				getEnterpriseDetailsByName,
				updateImageEnterprise,
			}}>
			{children}
		</UserContext.Provider>
	);
};
