import clsx from 'clsx';
import { Icon } from '@iconify/react';
import React, { useContext, useRef, useState } from 'react';
// import homeFill from '@iconify-icons/eva/home-fill';
import PopoverMenu from 'src/global/PopoverMenu';
import { Link as RouterLink } from 'react-router-dom';
import personFill from '@iconify-icons/eva/person-fill';
import settings2Fill from '@iconify-icons/eva/settings-2-fill';
import lockFill from '@iconify-icons/eva/lock-fill';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { Box, Avatar, Button, Divider, MenuItem, Typography } from '@material-ui/core';
import { MIconButton } from 'src/theme';

import { getUser, getUserRoles, logoutUser } from '../../../utils/sessionManager';
import { UserContext } from 'src/modules/user/core/context';
const currentUser = getUser();
const roles = getUserRoles();
// ----------------------------------------------------------------------
const MENU_OPTIONS = [
	{
		label: 'Profile',
		icon: personFill,
		linkTo: '/profile',
	},
	{
		label: 'Settings',
		icon: settings2Fill,
		linkTo: '#',
	},
	{
		label: 'Privacy Policy',
		icon: lockFill,
		linkTo: 'https://agriclear.io/privacyPolicy',
		target: true,
	},
];

const useStyles = makeStyles((theme) => ({
	menuItem: {
		...theme.typography.body2,
		padding: theme.spacing(1, 2.5),
	},
	btnAvatar: {
		padding: 0,
		width: 44,
		height: 44,
	},
	isSelected: {
		'&:before': {
			zIndex: 1,
			content: "''",
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			position: 'absolute',
			background: alpha(theme.palette.grey[900], 0.8),
		},
	},
}));

// ----------------------------------------------------------------------

function Account() {
	const classes = useStyles();
	const anchorRef = useRef(null);
	const [isOpen, setOpen] = useState(false);
	const { user_info } = useContext(UserContext);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const handleLogout = (e) => {
		e.preventDefault();
		logoutUser();
	};

	return (
		<>
			<MIconButton
				ref={anchorRef}
				onClick={handleOpen}
				className={clsx(classes.btnAvatar, { [classes.isSelected]: isOpen })}>
				<Avatar alt='My Avatar' src='/static/images/avatars/default_avatar.jpg' />
			</MIconButton>

			<PopoverMenu width={220} open={isOpen} onClose={handleClose} anchorEl={anchorRef.current}>
				<Box sx={{ my: 2, px: 2.5 }}>
					<Typography variant='subtitle1' noWrap>
						{/* {currentUser && currentUser.name ? `${currentUser.name.first} ${currentUser.name.last}` : 'Display Name'} */}
						{user_info?.wallet_address === currentUser?.wallet_address
							? `${user_info?.name?.first} ${user_info?.name?.last}`
							: `${currentUser?.name?.first} ${currentUser?.name?.last}`}
					</Typography>
					<Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
						{user_info?.wallet_address === currentUser?.wallet_address
							? user_info?.roles?.toString()
							: roles?.toString()}
					</Typography>
				</Box>

				<Divider sx={{ my: 1 }} />

				{MENU_OPTIONS.map((option) => (
					<MenuItem
						key={option.label}
						to={option?.target ? { pathname: option.linkTo } : option.linkTo}
						target={option?.target && '_blank'}
						component={RouterLink}
						onClick={handleClose}
						className={classes.menuItem}>
						<Box
							component={Icon}
							icon={option.icon}
							sx={{
								mr: 2,
								width: 24,
								height: 24,
							}}
						/>

						{option.label}
					</MenuItem>
				))}

				<Box sx={{ p: 2, pt: 1.5 }}>
					<Button fullWidth color='inherit' variant='outlined' onClick={(e) => handleLogout(e)}>
						Logout
					</Button>
				</Box>
			</PopoverMenu>
		</>
	);
}

export default Account;
