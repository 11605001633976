/* eslint-disable import/no-anonymous-default-export */
import ACTIONS from './actions';

export default (state, action) => {
	const result = action?.res?.data;

	switch (action.type) {
		case `${ACTIONS?.USER_DETAILS}`:
			return {
				...state,
				user_info: result,
			};

		case ACTIONS.LOGIN:
			return {
				...state,
				user_info: action.data,
			};
		case `${ACTIONS.SET_MAP_OPTIONS}`:
			return {
				...state,
				map_options: { ...state.map_options, lat: action.data.lat, lng: action.data.lng },
			};
		case `${ACTIONS.LIST_ALL_USERS}`: {
			return {
				...state,
				list: result.data,
				pagination: {
					limit: parseInt(result.limit),
					start: parseInt(result.start),
					total: parseInt(result.total),
					currentPage: parseInt(result.page),
					totalPages: Math.ceil(result.total / result.limit),
				},
			};
		}
		case `${ACTIONS?.UPDATE_ROLE}`: {
			return {
				...state,
				user_info: result,
			};
		}
		default:
			return state;
	}
};
